// From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/keys
import { validatePhoneNumber } from "./phone";
import moment from "moment";

if (!Object.keys) {
  Object.keys = (function() {
    "use strict";
    var hasOwnProperty = Object.prototype.hasOwnProperty,
      hasDontEnumBug = !{ toString: null }.propertyIsEnumerable("toString"),
      dontEnums = [
        "toString",
        "toLocaleString",
        "valueOf",
        "hasOwnProperty",
        "isPrototypeOf",
        "propertyIsEnumerable",
        "constructor"
      ],
      dontEnumsLength = dontEnums.length;

    return function(obj) {
      if (
        typeof obj !== "object" &&
        (typeof obj !== "function" || obj === null)
      ) {
        throw new TypeError("Object.keys called on non-object");
      }

      var result = [],
        prop,
        i;

      for (prop in obj) {
        if (hasOwnProperty.call(obj, prop)) {
          result.push(prop);
        }
      }

      if (hasDontEnumBug) {
        for (i = 0; i < dontEnumsLength; i++) {
          if (hasOwnProperty.call(obj, dontEnums[i])) {
            result.push(dontEnums[i]);
          }
        }
      }
      return result;
    };
  })();
}

if (!Object.keysMap) {
  Object.keysMap = (function() {
    "use strict";
    var hasOwnProperty = Object.prototype.hasOwnProperty,
      hasDontEnumBug = !{ toString: null }.propertyIsEnumerable("toString"),
      dontEnums = [
        "toString",
        "toLocaleString",
        "valueOf",
        "hasOwnProperty",
        "isPrototypeOf",
        "propertyIsEnumerable",
        "constructor"
      ],
      dontEnumsLength = dontEnums.length;

    return function(obj, valueKey = "name") {
      if (
        typeof obj !== "object" &&
        (typeof obj !== "function" || obj === null)
      ) {
        throw new TypeError("Object.keys called on non-object");
      }

      var result = [],
        prop,
        i;

      for (prop in obj) {
        if (hasOwnProperty.call(obj, prop)) {
          result.push({ id: obj[prop].id, value: obj[prop][valueKey] });
        }
      }

      // if (hasDontEnumBug) {
      //   for (i = 0; i < dontEnumsLength; i++) {
      //     if (hasOwnProperty.call(obj, dontEnums[i])) {
      //       result.push(dontEnums[i]);
      //     }
      //   }
      // }
      return result;
    };
  })();
}

/**

*

*  Base64 encode / decode

*  http://www.webtoolkit.info/

*

**/

export const Base64 = {
  // private property

  _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",

  // public method for encoding

  encode: function(input) {
    var output = "";

    var chr1, chr2, chr3, enc1, enc2, enc3, enc4;

    var i = 0;

    input = Base64._utf8_encode(input);

    while (i < input.length) {
      chr1 = input.charCodeAt(i++);

      chr2 = input.charCodeAt(i++);

      chr3 = input.charCodeAt(i++);

      enc1 = chr1 >> 2;

      enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);

      enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);

      enc4 = chr3 & 63;

      if (isNaN(chr2)) {
        enc3 = enc4 = 64;
      } else if (isNaN(chr3)) {
        enc4 = 64;
      }

      output =
        output +
        this._keyStr.charAt(enc1) +
        this._keyStr.charAt(enc2) +
        this._keyStr.charAt(enc3) +
        this._keyStr.charAt(enc4);
    }

    return output;
  },

  // public method for decoding

  decode: function(input) {
    var output = "";

    var chr1, chr2, chr3;

    var enc1, enc2, enc3, enc4;

    var i = 0;

    input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");

    while (i < input.length) {
      enc1 = this._keyStr.indexOf(input.charAt(i++));

      enc2 = this._keyStr.indexOf(input.charAt(i++));

      enc3 = this._keyStr.indexOf(input.charAt(i++));

      enc4 = this._keyStr.indexOf(input.charAt(i++));

      chr1 = (enc1 << 2) | (enc2 >> 4);

      chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);

      chr3 = ((enc3 & 3) << 6) | enc4;

      output = output + String.fromCharCode(chr1);

      if (enc3 != 64) {
        output = output + String.fromCharCode(chr2);
      }

      if (enc4 != 64) {
        output = output + String.fromCharCode(chr3);
      }
    }

    output = Base64._utf8_decode(output);

    return output;
  },

  // private method for UTF-8 encoding

  _utf8_encode: function(string) {
    string = string.replace(/\r\n/g, "\n");

    var utftext = "";

    for (var n = 0; n < string.length; n++) {
      var c = string.charCodeAt(n);

      if (c < 128) {
        utftext += String.fromCharCode(c);
      } else if (c > 127 && c < 2048) {
        utftext += String.fromCharCode((c >> 6) | 192);

        utftext += String.fromCharCode((c & 63) | 128);
      } else {
        utftext += String.fromCharCode((c >> 12) | 224);

        utftext += String.fromCharCode(((c >> 6) & 63) | 128);

        utftext += String.fromCharCode((c & 63) | 128);
      }
    }

    return utftext;
  },

  // private method for UTF-8 decoding

  _utf8_decode: function(utftext) {
    var string = "";

    var i = 0;

    var c = 0;
    var c1 = 0;
    var c2 = 0;
    var c3;

    while (i < utftext.length) {
      c = utftext.charCodeAt(i);

      if (c < 128) {
        string += String.fromCharCode(c);

        i++;
      } else if (c > 191 && c < 224) {
        c2 = utftext.charCodeAt(i + 1);

        string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));

        i += 2;
      } else {
        c2 = utftext.charCodeAt(i + 1);

        c3 = utftext.charCodeAt(i + 2);

        string += String.fromCharCode(
          ((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63)
        );

        i += 3;
      }
    }

    return string;
  }
};

export function findWithAttr(array, attr, value) {
  if (!array) {
    return -1;
  }
  for (let i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      return i;
    }
  }
  return -1;
}

export const validateEmail = email => {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const validateText = (text, minLength = 3, maxLength = 10000) => {
  return text.length >= minLength && text.length <= maxLength;
};

export function validateUserName(userName) {
  if (!userName) {
    return false;
  }

  // let regex = /^[A-Za-z0-9_]+$/; //letters, numbers and underline
  let regex = /^(?:[a-zA-Z0-9_.\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDCF\uFDF0-\uFDFF\uFE70-\uFEFF]|(?:\uD802[\uDE60-\uDE9F]|\uD83B[\uDE00-\uDEFF][\s*])){5,128}$/;
  let valid = regex.test(userName);
  if (!valid) {
    return validateEmail(userName) || validatePhoneNumber(userName);
  } else {
    let dots = 0;
    for (let i = 0; i < userName.length; i++) {
      if (userName[i] === ".") {
        dots++;
      }
    }
    if (dots > 1) {
      return false;
    }
  }

  return true;
}

export function validatePassword(password) {
  if (!password) {
    return false;
  }
  // let regex = /^(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])(?=\S+$).{6,}$/; //letters, numbers and underline
  let regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,128}$/; //letters, numbers and underline
  let valid = regex.test(password);
  return valid;
}

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export function romanize(num) {
  if (!+num) return NaN;
  var digits = String(+num).split(""),
    key = [
      "",
      "C",
      "CC",
      "CCC",
      "CD",
      "D",
      "DC",
      "DCC",
      "DCCC",
      "CM",
      "",
      "X",
      "XX",
      "XXX",
      "XL",
      "L",
      "LX",
      "LXX",
      "LXXX",
      "XC",
      "",
      "I",
      "II",
      "III",
      "IV",
      "V",
      "VI",
      "VII",
      "VIII",
      "IX"
    ],
    roman = "",
    i = 3;
  while (i--) roman = (key[+digits.pop() + i * 10] || "") + roman;
  return Array(+digits.join("") + 1).join("M") + roman;
}

function processArrayNode(node, id, data) {
  let result = { ...node };
  // console.log('processing ', node.data.id === id, node, node.data.id, id, data);
  if (node.data.id === id) {
    result.data = { ...node.data, ...data };
    if (data.title) result.title = data.title;
  } else if (node.children) {
    for (let i = 0; i < node.children.length; i++) {
      result.children[i] = processArrayNode(node.children[i], id, data);
    }
  }
  return result;
}

export function updateArray(array, id, data) {
  let result = array.slice(0);

  for (let i = 0; i < array.length; i++) {
    result[i] = processArrayNode(array[i], id, data);
  }

  return result;
}

function removeArrayNode(node, id) {
  let result = { ...node };
  // console.log('processing ', node.data.id === id, node, node.data.id, id, data);
  if (node.data.id === id) {
    console.log("FOIUND >>> REMOVE");
    return true;
  } else if (node.children) {
    result.children = [];
    for (let i = 0; i < node.children.length; i++) {
      let removeResult = removeArrayNode(node.children[i], id);
      // console.log('remove result', node.children[i].data.title, removeResult);
      if (removeResult !== true) {
        // console.log('push childrent ', i);
        result.children.push(removeResult);
      }
    }
  }

  return result;
}

export function removeNode(array, id) {
  console.log("REMOVE FROM ARRAY id", id);
  let result = [];

  for (let i = 0; i < array.length; i++) {
    let removeResult = removeArrayNode(array[i], id);
    // console.log('REMOVE RESULT', i, removeResult);
    if (removeResult != true) result.push(removeResult);
  }

  // console.log('REMOVING RESULT', result);

  return result;
}

function addArrayNode(node, id, data) {
  let result = { ...node };
  // console.log('processing ', node.data.id === id, node, node.data.id, id, data);
  if (node.data.id === id) {
    console.log("ADD FOUND", id, data);
    return true;
  } else if (node.children) {
    result.children = [];
    for (let i = 0; i < node.children.length; i++) {
      let addResult = addArrayNode(node.children[i], id, data);
      // console.log('push childrent ', i);

      if (addResult === true && node.children[i].data.type === "SECTION") {
        let el = { ...node.children[i] };
        if (!el.children) el.children = [];
        console.log("ADD TO ELEMENT", el);
        el.children = el.children.slice(0);
        el.children.push({ ...data });
        result.children.push({ ...el });
      } else if (addResult === true) {
        result.children.push({ ...node.children[i] });
        result.children.push({ ...data });
      } else {
        result.children.push({ ...addResult });
      }
    }
  }

  return result;
}

export function addNode(array, id, data, oneLevelSection = false) {
  console.log("ADD NODE \nid", id, "\ndata", data, "\ndata id", data.data.id);
  let result = [];

  if (id === false) {
    return array.slice(0).concat([{ ...data }]);
  }

  for (let i = 0; i < array.length; i++) {
    let addResult = addArrayNode(array[i], id, data);
    // console.log('ADD RESULT', i, addResult);

    if (
      addResult === true &&
      array[i].data.type === "SECTION" &&
      data.data.type !== "SECTION"
    ) {
      console.log("INSERT 1");
      let el = { ...array[i] };
      if (!el.children) el.children = [];
      console.log("add element ", el.children.slice(0));
      el.children = el.children.slice(0);
      el.children.push({ ...data });
      result.push({ ...el });
    } else if (addResult === true) {
      console.log("INSERT 2");
      result.push({ ...array[i] });
      result.push({ ...data });
    } else {
      console.log("INSERT 3");
      result.push({ ...addResult });
    }
  }

  // console.log('adding RESULT', result);

  return result;
}

export function guid() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function parseURLQueryParams(search) {
  //search: "?cp=email"
  if (!search || !search.substring(1)) {
    return {};
  }
  search = search.substring(1);
  let params = search.split("&");
  if (params && params.length > 0) {
    let out = {};
    params.forEach(p => {
      let values = p.split("=");
      out[values[0]] = values[1];
    });
    return out;
  }
  return {};
}

export function isAbsoluteURL(url) {
  return !!(
    url &&
    (url.indexOf("http://") === 0 || url.indexOf("https://") === 0)
  );
}

export const months = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec"
};

export function formatSlotDate(start, end) {
  return moment(start).format("LLLL") + " - " + moment(end).format("LT");
}

export function getAppointmentArrivalTime(arriveBeforeMins, start) {
  let d = new Date(start.getTime() - arriveBeforeMins * 60 * 1000);
  return moment(d).format("LLLL");
}

export function getLangFromLocale(locale) {
  if (!locale) {
    return;
  }
  let parts = locale.split("_");
  return parts[0];
}
export function getCountryFromLocale(locale) {
  if (!locale) {
    return;
  }
  let parts = locale.split("_");
  return parts[1];
}

export function secondsToHms(d) {
  d = Number(d);

  var h = Math.floor(d / 60);
  var m = Math.floor(d % 60);
  // var s = Math.floor((d % 60) % 60);

  let str = "";
  if (h > 0) {
    str += `${h} hr`;
  }
  if (m > 0) {
    str += ` ${m} min`;
  }
  // str += ` ${s} sec`;
  return str;
}

export function secondsToHourMinSecs(d) {
  d = Number(d);

  let h = Math.floor(d / 3600);
  let remainder = Math.floor(d % 3600);
  let m = Math.floor(remainder / 60);
  let s = Math.floor(remainder % 60);

  let str = "";
  if (h > 0) {
    str += `${h} hr`;
  }
  if (m > 0) {
    str += ` ${m} min`;
  }
  str += ` ${s} sec`;
  return str;
}

export function isIE() {
  var ua = window.navigator.userAgent;
  var msie = ua.indexOf("MSIE ");
  return msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);
}

export function getDomain() {
  let url = window.location.href;
  let parts = url.split("/");
  return parts[2] || "";
}

export function scrollMainLayoutY(y) {
  doScrollY("main-layout", y);
}

export function doScrollY(elId, y) {
  let element = document.getElementById(elId);
  if (element.scrollTo) {
    element.scrollTo(0, y);
  } else {
    element.scrollTop = y;
  }
}

export function getScreeningSurveyId(screening) {
  let { screeningSurveyLink, screeningResponseLink } = screening;

  if (screeningSurveyLink) {
    let parts = screeningSurveyLink.split("/");
    if (parts.length === 4) {
      return parts[3];
    }
  }
  if (screeningResponseLink) {
    let parts = screeningResponseLink.split("/");
    if (parts.length === 4) {
      return parts[3];
    }
  }
  return null;
}

export function strReplaceLast(text, which, what) {
  let n = text.lastIndexOf(which);
  if (n === -1) {
    return text;
  }

  return text.slice(0, n) + text.slice(n).replace(which, what);
}

export function stripHTML(html) {
  // var tmp = document.createElement("DIV");
  // tmp.innerHTML = html;
  // return tmp.textContent || tmp.innerText || "";
  if (!html) {
    return "";
  }
  return html.replace(/(<([^>]+)>)/gi, " ");
}

export function truncateText(text, length = 75) {
  return text.length > length ? text.substr(0, length - 1) + "..." : text;
}

export const isMobile = (() => {
  if (
    typeof navigator === "undefined" ||
    typeof navigator.userAgent !== "string"
  ) {
    return false;
  }
  return /Mobile/.test(navigator.userAgent);
})();

export function formatDateTimeWithTimezone(date) {
  const format = "DD/MM/YY HH:mm";
  const momentDate = moment(date);
  const formattedDate = momentDate.format(format);
  const timezoneOffset = momentDate.format("Z"); // Gets the timezone offset (e.g., "+02:00")
  return `${formattedDate} (GMT${timezoneOffset})`;
}