export const REACT_APP_HOSTING_ENV =
  process.env.REACT_APP_HOSTING_ENV || "main-dev";

const Config = (env) => {
  switch (env) {
    case "main-prod": {
      return [
        {
          env: "production",
          region: "EU",
          url: "https://api.mypatientspace.com",
          regionCode: "eu",
          appURL: "https://web.mypatientspace.com",
        },
        // {
        //   env: "production",
        //   region: "LATAM",
        //   url: "https://api.mypatientspace.com",
        //   regionCode: "latam",
        //   appURL: "https://web.mypatientspace.com",
        // },
        {
          env: "production",
          region: "GCC",
          url: "https://uaeapi.mypatientspace.com",
          regionCode: "gcc",
          appURL: "https://web.mypatientspace.com",
        },
        {
          env: "production",
          region: "AZURE_TEST",
          url: "https://app-49965.on-aptible.com",
          regionCode: "eu",
          appURL: "https://web.mypatientspace.com",
        },
        {
          env: "production",
          region: "ATLAS",
          url: "https://app-54281.on-aptible.com",
          regionCode: "eu",
          appURL: "https://web.mypatientspace.com",
        },
      ];
    }
    case "uat": {
      return [
        {
          env: "production",
          region: "EU",
          url: "https://uatapi.mypatientspace.com",
          regionCode: "eu",
          appURL: "https://uat.mypatientspace.com",
        },
      ];
    }
    case "pre-prod": {
      return [
        {
          env: "production",
          region: "EU",
          url: "https://testapi.mypatientspace.com",
          regionCode: "eu",
          appURL: "https://test.mypatientspace.com",
        },
        {
          env: "production",
          region: "LATAM",
          url: "https://preprodapi.mypatientspace.com",
          regionCode: "latam",
          appURL: "https://preprod.mypatientspace.com",
        },
        {
          env: "production",
          region: "GCC",
          url: "https://preprodapi.mypatientspace.com",
          regionCode: "gcc",
          appURL: "https://preprod.mypatientspace.com",
        },
        {
          env: "production",
          region: "UAT",
          url: "https://uatapi.mypatientspace.com",
          regionCode: "uat",
          appURL: "https://uat.mypatientspace.com",
        },
      ];
    }
    case "staging": {
      return [
        {
          env: "production",
          region: "EU",
          url: "https://stagingapi.mypatientspace.com",
          regionCode: "eu",
          appURL: "https://app-24260.on-aptible.com",
        },
      ];
    }

    case "main-dev":
    default: {
      return [
        {
          env: "development",
          region: "PROD",
          url: "https://api.mypatientspace.com",
          regionCode: "eu",
          appURL: "https://web.mypatientspace.com",
        },
        {
          env: "development",
          region: "PREPROD",
          url: "https://preprodapi.mypatientspace.com",
          regionCode: "preprod",
          appURL: "https://preprod.mypatientspace.com",
        },
        {
          env: "development",
          region: "TEST",
          url: "https://testapi.mypatientspace.com",
          regionCode: "test",
          appURL: "https://test.mypatientspace.com",
        },
        {
          env: "development",
          region: "UAT",
          url: "https://uatapi.mypatientspace.com",
          regionCode: "test",
          appURL: "https://uat.mypatientspace.com",
        },
      ];
    }
  }
};

export default Config(REACT_APP_HOSTING_ENV);
